var exports = {};

exports.init = function (grunt) {
  var _ = grunt.util._;
  var exports = {};

  _.mixin({
    /* _.componentize: Converts dashes to spaces, and 'css' to 'CSS' */
    componentize: function (str) {
      return str.replace(/-/g, " ").replace(/css/g, "CSS");
    }
  });

  return exports;
};

export default exports;